
const block = {

    "x" : 1,
    "y" : 1,
    "location": [[0,0],[0,1],[1,0],[1,1]]
}

const beehive = {
    "x": 3,
    "y": 2,
    "location": [[0,1],[0,2],[1,0],[1,3],[2,1],[2,2]]
}

const loaf = {
    "x": 3,
    "y": 3,
    "location": [[0,1],[0,2],[1,0],[1,3],[2,1],[2,3],[3,2]]
}

const boat = {
    "x": 2,
    "y": 2,
    "location": [[0,0],[0,1],[1,0],[1,2],[2,1]]
}

const tub = {
    "x":2,
    "y": 2,
    "location": [[0,1],[1,0],[1,2],[2,1]]
}

const blinker = {
    "x": 2,
    "y": 2,
    "location": [[0,1],[1,1],[2,1]]
}

const toad = {
    "x": 3,
    "y": 3,
    "location": [[1,1],[1,2],[1,3],[2,0],[2,1],[2,2]]
}

const beacon = {
    "x": 3,
    "y": 3,
    "location": [[0,0],[0,1],[1,0],[2,3],[3,2],[3,3]]
}

const pulsar = {
    "x": 15,
    "y": 15,
    "location": [[1,3],[1,4],[1,5],[1,9],[1,10],[1,11],[3,1],[3,6],[3,8],[3,13],[4,1],[4,6],[4,8],[4,13],[5,1],[5,6],[5,8],[5,13],[6,3],[6,4],[6,5],[6,9],[6,10],[6,11],
                [8,3],[8,4],[8,5],[8,9],[8,10],[8,11],[9,1],[9,6],[9,8],[9,13],[10,1],[10,6],[10,8],[10,13],[11,1],[11,6],[11,8],[11,13],[13,3],[13,4],[13,5],[13,9],[13,10],[13,11]]
}

const penta_decathlon = {
    "x": 11,
    "y": 16,
    "location": [[2,3],[2,4],[2,5],[3,4],[4,4],[5,3],[5,4],[5,5],[7,3],[7,4],[7,5],
                [8,3],[8,4],[8,5],[10,3],[10,4],[10,5],[11,4],[12,4],[13,3],[13,4],[13,5]]
}

const glider = {
    "x": 2,
    "y": 2,
    "location": [[0,0],[1,1],[1,2],[2,0],[2,1]]
}

const lwss = {
    "x": 4,
    "y": 4,
    "location": [[0,0],[0,3],[1,4],[2,0],[2,4],[3,1],[3,2],[3,3],[3,4]]
}

const mwss = {
    "x": 5,
    "y": 6,
    "location": [[2,1],[2,2],[2,3],[2,4],[2,5],[3,0],[3,5],[4,5],[5,0],[5,4],[6,2]]
}

const hwss = {
    "x": 7,
    "y": 7,
    "location": [[2,1],[2,2],[2,3],[2,4],[2,5],[2,6],[3,0],[3,6],[4,6],[5,0],[5,5],[6,2],[6,3]]
}

export default {block, beehive, loaf, boat, tub, blinker, 
                toad, beacon, pulsar, penta_decathlon, glider, 
                lwss, mwss, hwss}